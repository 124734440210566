const buttons = document.querySelectorAll('[data-js="disclosure-trigger"]');
const footers = document.querySelectorAll('.p-workshopSummary__footer');
const links = document.querySelectorAll('.p-workshopSummary__link');
const texts = document.querySelectorAll('.p-workshopSummary__text');

export function setStateDisclosure() {
  const windowWidth = window.innerWidth;
  const PC_WIDTH = 980;

  if (windowWidth > PC_WIDTH) {
    buttons.forEach(button => button.setAttribute('hidden', true));
    footers.forEach(footer => footer.removeAttribute('aria-hidden'));
    links.forEach(link => link.removeAttribute('tabindex'));
    texts.forEach(text => text.removeAttribute('aria-hidden'));
  } else {
    buttons.forEach(button => button.removeAttribute('hidden'));
    footers.forEach(footer => footer.setAttribute('aria-hidden', 'true'));
    links.forEach(link => link.setAttribute('tabindex', '-1'));
    texts.forEach(text => text.setAttribute('aria-hidden', 'true'));
  }
}

export function disclosure() {
  buttons.forEach(button => {
    const parent = button.parentNode;
    const footer = parent.querySelector('.p-workshopSummary__footer');
    const link = footer.querySelector('.p-workshopSummary__link');
    const text = parent.querySelector('.p-workshopSummary__text');

    button.addEventListener('click', () => {
      const isExpanded = button.getAttribute('aria-expanded') === 'true';
      button.setAttribute('aria-expanded', String(!isExpanded));
      footer.setAttribute('aria-hidden', String(isExpanded));
      link.removeAttribute('tabindex');
      text.setAttribute('aria-hidden', String(isExpanded));
    });
  });
}
