export default function ticket() {
  const dayTicket = document.getElementById('dayTicket');

  if (dayTicket === null) return;

  const tdAll = dayTicket.querySelectorAll('td');
  const tdTodayArray = Array.from(tdAll).slice(0, 5);

  tdAll.forEach(element => {
    const elementClassList = element.classList;
    const iconWrap = element.querySelector('.p-ticketInfo__iconWrap');
    let infoText = '';

    if (
      !(
        elementClassList.contains('-enabled') ||
        elementClassList.contains('-disabled') ||
        elementClassList.contains('-ended')
      )
    ) {
      elementClassList.add('-ended');
    }
    if (elementClassList.contains('-enabled')) {
      infoText = '販売中';
    } else if (elementClassList.contains('-disabled')) {
      infoText = '販売しておりません';
    } else if (elementClassList.contains('-ended')) {
      infoText = '販売終了';
    }
    if (infoText.length !== 0) {
      iconWrap.insertAdjacentHTML(
        'beforeend',
        `<span class="p-ticketInfo__text">${infoText}</span>`,
      );
    }
  });

  const date = new Date();
  const dateHours = String(date.getHours()).padStart(2, '0');
  const dateMinutes = String(date.getMinutes()).padStart(2, '0');
  const dateTime = `${dateHours}:${dateMinutes}`;
  const changeEnded = element => {
    if (!element.classList.contains('-ended')) {
      element.classList.remove('-enabled');
      element.classList.remove('-disabled');
      element.classList.add('-ended');
    }
  };

  if (dateTime >= '00:00') {
    if (dateTime >= '19:30') {
      tdTodayArray.forEach(changeEnded);
    } else if (dateTime >= '17:30') {
      tdTodayArray.slice(0, 4).forEach(changeEnded);
    } else if (dateTime >= '15:30') {
      tdTodayArray.slice(0, 3).forEach(changeEnded);
    } else if (dateTime >= '13:30') {
      tdTodayArray.slice(0, 2).forEach(changeEnded);
    } else if (dateTime >= '11:30') {
      changeEnded(tdTodayArray[0]);
    }
  }
}
