export default function carousel() {
  const roots = document.querySelectorAll('.js-carousel');
  roots.forEach(root => {
    const container = root.querySelector('.js-carouselContainer');
    const stage = root.querySelector('.js-carouselStage');
    const content = root.querySelector('.js-carouselContent');
    const items = root.querySelectorAll('.js-carouselItem');
    const footer = root.querySelector('.js-carouselFooter');

    const itemsLength = items.length;
    const lastIndex = itemsLength - 1;
    let currentIndex;

    function createButton(classNames, text) {
      const button = document.createElement('button');
      const textNode = document.createTextNode(text);
      button.setAttribute('type', 'button');
      classNames.forEach(className => {
        button.classList.add(className);
      });
      button.appendChild(textNode);
      return button;
    }

    const prevButton = createButton(
      ['c-carousel__button', '-prev'],
      '前のパネルを表示する',
    );
    const nextButton = createButton(
      ['c-carousel__button', '-next'],
      '次のパネルを表示する',
    );
    const controllersDisabledStatus = {
      prev: false,
      next: false,
    };

    function createIndicator(count) {
      const ol = document.createElement('ol');
      const buttons = [];
      for (let i = 0; i < count; i += 1) {
        const li = document.createElement('li');
        const button = createButton(['c-carousel__indicatorButton'], i + 1);
        li.classList.add('c-carousel__indicatorItem');
        li.appendChild(button);
        ol.appendChild(li);
        buttons.push(button);
      }
      ol.classList.add('c-carousel__indicator');

      return {
        list: ol,
        buttons,
      };
    }

    const nav = createIndicator(itemsLength);
    const indicator = nav.list;
    const indicatorButtons = nav.buttons;

    const thresholdBase = 0.2;
    const pointer = {
      startX: 0,
      moveX: 0,
      hold: false,
    };
    let currentTransformValue = '';

    function appendNavigations() {
      const controllersFragment = document.createDocumentFragment();
      controllersFragment.appendChild(prevButton);
      controllersFragment.appendChild(nextButton);
      container.appendChild(controllersFragment);
      footer.appendChild(indicator);
    }

    function updateControllerDisabledProp(button, key, disablingCondition) {
      const needUpdate = disablingCondition !== controllersDisabledStatus[key];
      if (!needUpdate) {
        return;
      }
      button.disabled = disablingCondition; // eslint-disable-line no-param-reassign
      controllersDisabledStatus[key] = disablingCondition;
    }

    function updateIndicatorDisabledProp(index) {
      if (currentIndex > -1) {
        indicatorButtons[currentIndex].disabled = false;
      }
      indicatorButtons[index].disabled = true;
    }

    function slideAnim(index) {
      const distance = -100 * index;
      const transformValue = `translateX(${distance}%)`;
      content.style.transform = transformValue;
      currentTransformValue = transformValue;
    }

    function changeItem(index) {
      if (index === currentIndex) {
        return;
      }
      slideAnim(index);
      updateControllerDisabledProp(prevButton, 'prev', index === 0);
      updateControllerDisabledProp(nextButton, 'next', index === lastIndex);
      updateIndicatorDisabledProp(index);
      currentIndex = index;
    }

    function onClickPrevButton() {
      const prevIndex = currentIndex - 1;
      if (prevIndex < 0) {
        return;
      }
      changeItem(prevIndex);
    }

    function onClickNextButton() {
      const nextIndex = currentIndex + 1;
      if (nextIndex > lastIndex) {
        return;
      }
      changeItem(nextIndex);
    }

    function onClickIndicatorButton(event) {
      const { target } = event;
      if (target.tagName.toLowerCase() !== 'button') {
        return;
      }
      const index = indicatorButtons.indexOf(target);
      changeItem(index);
    }

    function pointerDown(clientX) {
      pointer.startX = clientX;
      pointer.hold = true;
      content.style.transitionDuration = '0s';
    }

    function onTouchstart(event) {
      if (event.targetTouches.length > 1) {
        return;
      }
      pointerDown(event.targetTouches[0].clientX);
    }

    function onPointerdown(event) {
      if (event.pointerType !== 'touch' || !event.isPrimary) {
        return;
      }
      event.preventDefault();
      pointerDown(event.clientX);
    }

    function pointerMove(clientX) {
      const moveX = clientX - pointer.startX;
      const additionalTransformValue = `translateX(${moveX}px)`;
      pointer.moveX = moveX;
      content.style.transform = `${currentTransformValue} ${additionalTransformValue}`;
    }

    function onTouchmove(event) {
      if (event.targetTouches.length > 1 || !pointer.hold) {
        return;
      }
      pointerMove(event.targetTouches[0].clientX);
    }

    function onPointermove(event) {
      if (event.pointerType !== 'touch' || !event.isPrimary || !pointer.hold) {
        return;
      }
      event.preventDefault();
      pointerMove(event.clientX);
    }

    function pointerUp() {
      function getIndexSwipeEnd() {
        const absoluteMoveX = Math.abs(pointer.moveX);
        const addIndex = pointer.moveX > 0 ? -1 : 1;
        if (
          absoluteMoveX < container.clientWidth * thresholdBase ||
          (currentIndex === 0 && addIndex === -1) ||
          (currentIndex === lastIndex && addIndex === 1)
        ) {
          return currentIndex;
        }
        return currentIndex + addIndex;
      }
      const nextIndex = getIndexSwipeEnd();
      content.style.transitionDuration = '';
      if (nextIndex === currentIndex) {
        content.style.transform = currentTransformValue;
      } else {
        changeItem(nextIndex);
      }
      pointer.startX = 0;
      pointer.moveX = 0;
      pointer.hold = false;
    }

    function onTouchend(event) {
      if (event.targetTouches.length > 1 || !pointer.hold) {
        return;
      }
      pointerUp();
    }

    function onPointerup(event) {
      if (event.pointerType !== 'touch' || !event.isPrimary || !pointer.hold) {
        return;
      }
      event.preventDefault();
      pointerUp();
    }

    function addTouchActionNone() {
      stage.style.touchAction = 'none';
    }

    function init() {
      prevButton.addEventListener('click', onClickPrevButton, false);
      nextButton.addEventListener('click', onClickNextButton, false);
      indicator.addEventListener('click', onClickIndicatorButton, false);
      if ('PointerEvent' in window) {
        addTouchActionNone();
        stage.addEventListener('pointerdown', onPointerdown, false);
        stage.addEventListener('pointermove', onPointermove, false);
        stage.addEventListener('pointerup', onPointerup, false);
        stage.addEventListener('pointercancel', onPointerup, false);
      } else if ('ontouchstart' in window) {
        stage.addEventListener('touchstart', onTouchstart, false);
        stage.addEventListener('touchmove', onTouchmove, false);
        stage.addEventListener('touchend', onTouchend, false);
        stage.addEventListener('touchcancel', onTouchend, false);
      }
      appendNavigations();
      changeItem(0);
    }

    init();
  });
}
